.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;
  overflow: hidden;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.home-container01 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #000000;
}
.home-main {
  top: 0px;
  flex: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 122;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 0px;
  height: 0px;
  opacity: 0;
  z-index: 200;
  position: absolute;
  font-size: 1px;
}
.home-background {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  top: 0px;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: auto;
  z-index: 99;
  position: absolute;
  object-fit: cover;
}
.home-video {
  width: 100%;
  filter: blur(5px) brightness(0.8) contrast(1.1);
  height: auto;
  z-index: 1;
}
.home-slogan {
  width: 100%;
  height: 60%;
  display: flex;
  z-index: 100;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text01 {
  color: #ffffff;
  width: auto;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-size: 150px;
  font-style: normal;
  margin-top: 5%;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 800;
  margin-left: 0%;
}
.home-container02 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 40%;
  display: flex;
  z-index: 100;
  position: relative;
  align-self: flex-start;
  align-items: center;
  margin-left: 0%;
  margin-right: 0%;
  padding-left: 0%;
  margin-bottom: 0%;
  flex-direction: column;
  justify-content: center;
}
.home-text05 {
  color: #ffffff;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-size: 24px;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 800;
  line-height: 0.1;
  margin-bottom: 0px;
}
.home-text08 {
  color: #dcdcdc;
  width: 50%;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 300;
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text10 {
  font-weight: 500;
}
.home-text12 {
  font-weight: 500;
}
.home-container03 {
  gap: var(--dl-space-space-twounits);
  flex: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-playnow {
  width: 167px;
  display: flex;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 800;
  border-width: 0px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  background-color: #50ff80;
  transition-timing-function: ease-in-out;
}
.home-playnow:hover {
  cursor: pointer;
  transform: scale(1.25);
  background-color: #b9f3a1;
}
.home-playnow:active {
  transform: scale(1);
  transition: 0.1s;
  background-color: #3cc964;
}
.home-text14 {
  color: #000000;
  font-size: 12px;
  line-height: 1.75;
}
.home-learnmore {
  width: 167px;
  display: flex;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 800;
  border-width: 0px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #ffffff;
  transition-timing-function: ease-in-out;
}
.home-learnmore:hover {
  cursor: pointer;
  transform: scale(1.25);
  background-color: #b9f3a1;
}
.home-learnmore:active {
  transform: scale(1);
  background: #3cc964;
  transition: 0.1s;
}
.home-text15 {
  color: #000000;
  font-size: 12px;
  line-height: 1.75;
}
.home-ourteam {
  width: 167px;
  display: flex;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 800;
  border-width: 0px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #ffffff;
  transition-timing-function: ease-in-out;
}
.home-ourteam:hover {
  cursor: pointer;
  transform: scale(1.25);
  background-color: #b9f3a1;
}
.home-ourteam:active {
  transform: scale(1);
  transition: 0.1s;
  background-color: #3cc964;
}
.home-text18 {
  color: #000000;
  font-size: 12px;
  line-height: 1.75;
}
.home-endtext {
  left: 0px;
  color: #dcdcdc;
  right: 0px;
  width: auto;
  bottom: 0px;
  cursor: pointer;
  height: auto;
  position: absolute;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-size: 12px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
  user-select: none;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 13px;
  text-decoration: none;
  animation-timing-function: ease-in-out;
}
.home-endtext:hover {
  color: #b9f3a1;
  transform: scale(1.25);
}
.home-endtext:active {
  color: #3cc964;
  transform: scale(0.75);
  transition: 0.2s;
}
.home-text22 {
  text-decoration: underline;
}
.home-learnmore1 {
  top: 100%;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 144;
  overflow: hidden;
  position: absolute;
  align-items: center;
  padding-top: 0%;
  padding-left: 0%;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container04 {
  gap: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 0%;
  flex-direction: column;
}
.home-gamemodes {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: 3%;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text24 {
  color: #ffffff;
  font-size: 24px;
}
.home-text27 {
  color: #dcdcdc;
  width: 100%;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 300;
  padding-top: 2px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container05 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  height: 45%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-container06 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: 350px;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #50ff80;
  animation-timing-function: ease-in-out;
}
.home-container06:hover {
  transform: scale(1.1);
}
.home-container07 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/out-2022-06-26_15.08.00%20-%20kopie-400h.png");
  background-position: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-text28 {
  top: 0px;
  left: 0px;
  color: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: var(--dl-space-space-unit);
  position: absolute;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 400;
  user-select: none;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
.home-text28:hover {
  opacity: 1;
}
.home-title {
  color: #000000;
  width: 100%;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 700;
  user-select: none;
}
.home-container08 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: 350px;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #50ff80;
  animation-timing-function: ease-in-out;
}
.home-container08:hover {
  transform: scale(1.1);
}
.home-container09 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/out-2022-11-04_13.04.03-400h.png");
  background-position: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-text31 {
  top: 0px;
  left: 0px;
  color: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: var(--dl-space-space-unit);
  position: absolute;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 400;
  user-select: none;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
.home-text31:hover {
  opacity: 1;
}
.home-title1 {
  color: #000000;
  width: 100%;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 700;
  user-select: none;
}
.home-container10 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: 350px;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #50ff80;
  animation-timing-function: ease-in-out;
}
.home-container10:hover {
  transform: scale(1.1);
}
.home-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/out-2022-08-06_13.06.30%20-%20kopie-400h.png");
  background-position: top;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-text34 {
  top: 0px;
  left: 0px;
  color: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: var(--dl-space-space-unit);
  position: absolute;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 400;
  user-select: none;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
.home-text34:hover {
  opacity: 1;
}
.home-title2 {
  color: #000000;
  width: 100%;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 700;
  user-select: none;
}
.home-text35 {
  color: #dcdcdc;
  width: 100%;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 300;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.home-text36 {
  font-weight: 500;
}
.home-scrolltop {
  right: 0px;
  width: var(--dl-size-size-large);
  bottom: 0px;
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  z-index: 101;
  position: absolute;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 800;
  border-width: 0px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #50ff80;
  transition-timing-function: ease-in-out;
}
.home-scrolltop:hover {
  cursor: pointer;
  transform: scale(1.25);
  background-color: #b9f3a1;
}
.home-scrolltop:active {
  transform: scale(1);
  transition: 0.1s;
  background-color: #3cc964;
}
.home-text39 {
  color: #000000;
  font-size: 12px;
  line-height: 1.75;
}
.home-media {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: 3%;
  z-index: 100;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container12 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-iframe {
  width: 40%;
  height: 100%;
  padding: 0px;
  max-width: 550px;
  border-color: #50ff80;
  border-width: 3px;
  border-radius: 10px;
}
.home-image1 {
  width: 40%;
  height: 100%;
  padding: 0px;
  max-width: 550px;
  object-fit: cover;
  border-color: #50ff80;
  border-width: 3px;
  border-radius: 10px;
}
.home-text42 {
  color: #dcdcdc;
  width: 100%;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-discord {
  width: var(--dl-size-size-xlarge);
  display: flex;
  position: relative;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  align-items: center;
  font-family: Open Sans;
  font-weight: 800;
  border-width: 0px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #7289da;
  transition-timing-function: ease-in-out;
}
.home-discord:hover {
  cursor: pointer;
  transform: scale(1.25);
  background-color: #b9f3a1;
}
.home-discord:active {
  transform: scale(1);
  transition: 0.1s;
  background-color: #3cc964;
}
.home-image2 {
  width: auto;
  height: 12px;
  margin-top: 1px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.home-text45 {
  color: #ffffff;
  font-size: 12px;
  line-height: 1.75;
}
.home-container13 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  filter: blur(8px) brightness(0.7);
  height: 100%;
  display: none;
  z-index: 10;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/out-filme%20%26%20tv%2030.10.2022%2020_33_52-1500h.png");
}
.home-ourteam1 {
  top: 200%;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 144;
  overflow: hidden;
  position: absolute;
  align-items: center;
  padding-top: 0%;
  padding-left: 0%;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container14 {
  gap: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 0%;
  flex-direction: column;
  justify-content: center;
}
.home-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
}
.home-text48 {
  color: #ffffff;
  font-size: 24px;
}
.home-text51 {
  color: #dcdcdc;
  width: 100%;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 300;
  padding-top: 2px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-teammembers {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-admin {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home-builder {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home-test-supporter {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home-support {
  width: var(--dl-size-size-large);
  display: flex;
  animation: 0.25s ease-in-out 0s 1 scaleFade;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  text-align: center;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 800;
  border-width: 0px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #ffffff;
  transition-timing-function: ease-in-out;
}
.home-support:hover {
  cursor: pointer;
  transform: scale(1.25);
  background-color: #b9f3a1;
}
.home-support:active {
  transform: scale(1);
  transition: 0.1s;
  background-color: #3cc964;
}
.home-text54 {
  color: #000000;
  font-size: 12px;
  line-height: 1.75;
}
.home-scrolltop1 {
  right: 0px;
  width: var(--dl-size-size-large);
  bottom: 0px;
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: absolute;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: Open Sans;
  font-weight: 800;
  border-width: 0px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #50ff80;
  transition-timing-function: ease-in-out;
}
.home-scrolltop1:hover {
  cursor: pointer;
  transform: scale(1.25);
  background-color: #b9f3a1;
}
.home-scrolltop1:active {
  transform: scale(1);
  transition: 0.1s;
  background-color: #3cc964;
}
.home-text57 {
  color: #000000;
  font-size: 12px;
  line-height: 1.75;
}
@media(max-width: 479px) {
  .home-slogan {
    height: 50%;
  }
  .home-text01 {
    font-size: 50px;
  }
  .home-container02 {
    height: 50%;
    justify-content: flex-start;
  }
  .home-text05 {
    font-size: 20px;
  }
  .home-text08 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container03 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex: 0;
    flex-wrap: wrap;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
  }
  .home-playnow {
    width: var(--dl-size-size-large);
  }
  .home-learnmore1 {
    top: 100%;
  }
  .home-container05 {
    gap: var(--dl-space-space-unit);
    height: 30%;
  }
  .home-container06 {
    width: 30%;
  }
  .home-container08 {
    width: 30%;
  }
  .home-container10 {
    width: 30%;
  }
  .home-media {
    justify-content: flex-start;
  }
  .home-container12 {
    height: 30%;
  }
  .home-ourteam1 {
    top: 200%;
  }
}
